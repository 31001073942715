import { React, useEffect, useState, useRef, useParams } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";

import arrow from './images/arrow.svg';
import './App.scss';


import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';


import Home from './components/home'
import Navigation from './components/navigation'
import About from './components/about'
import Contact from './components/contact'
import Loader from './components/loader'
import Skills from './components/skills'
import Projects from './components/projects'

const App = () => {
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 3500);
      }, []);
      
      const loaderStyle = {
          width: "50px"
      }
      
  {/*let { customer } = useParams();*/}
  return (
    <Router>
     <div className="App">
      {loading ? (
              <Loader />
            ) : (
       <div className="main-container">
         <header>
            <Navigation />
           <Routes>
             <Route exact path="/" element={<Home/>}  />
             <Route exact path="/:customer" element={<Home/>}  />
             <Route path="/projects" element={<Projects/>}  />
             <Route path="/skills" element={<Skills/>}  />
             <Route path="/about" element={<About/>}  />
             <Route path="/contact" element={<Contact/>}  />
             <Route path="/:customer" element={<Home/>} />
           </Routes>
         </header>
       
     </div>
       
   
     )}
    </div>
  </Router>  
  );
}
export default App;
