import React, { Component, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "./LanguageSwitcher";
import ReactDOM from "react-dom";
import logo from '../logo.svg';
import dunes from '../dunes.svg';
import github from '../icon-github.svg';
import linkedin from '../icon-linkedin.svg';
import '../App.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
const lngs = [
  { code: "en", native: "English" },
  { code: "ja", native: "日本語" },
];
const myLoc = window.location.href.split('/')[3]
console.log(myLoc);
const Navigation = props => {
    const { location } = props;
    const { t, i18n } = useTranslation();
    const handleTrans = (code) => {
        i18n.changeLanguage(code);
      };
        return (
           <Navbar expand="lg" className="navbar navbar-expand-lg navbar-dark position-absolute">          
              <Container>
                {/* NavBar Brand  */}             
                  <Navbar.Brand href="/"><img src={dunes} width="45" height="auto"/>&nbsp;&nbsp;<strong>DUNES</strong></Navbar.Brand>
                  <Navbar.Toggle aria-controls={'offcanvasNavbar-expand-${expand}'} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="top"
                    data-bs-scroll="false"
                    data-bs-backdrop="false"
                  >

                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3 myMenumd">
                        <Nav.Link className={myLoc == 'projects'  ? 'active' : 'none'} href="projects">{t('menu_projects')}</Nav.Link>
                        <Nav.Link className={myLoc == 'skills'  ? 'active' : 'none'} href="skills">{t('menu_skills')}</Nav.Link>
                        <Nav.Link className={myLoc == 'contact'  ? 'active' : 'none'} href="contact">{t('menu_contact')}</Nav.Link>
                        <Nav.Link href="https://www.linkedin.com/in/julien-b-6179a818/"><img src={linkedin} width="14" height="auto"/></Nav.Link>
                        <Nav.Link href="https://github.com/airbuzz"> <img src={github} width="14" height="auto"/></Nav.Link>
                        <Nav><LanguageSwitcher /></Nav>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                        
              </Container>
        </Navbar>
        )
}

export default Navigation